/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

@import "../node_modules/leaflet/dist/leaflet.css";

@import "../node_modules/ngx-toastr/toastr.css";

@font-face {
  font-family: "EncodeSansLight";
  src: url("./assets/fonts/EncodeSans/EncodeSans-Light.ttf");
}

@font-face {
  font-family: "EncodeSansRegular";
  src: url("./assets/fonts/EncodeSans/EncodeSans-Regular.ttf");
}
@font-face {
  font-family: "EncodeSansMedium";
  src: url("./assets/fonts/EncodeSans/EncodeSans-Medium.ttf");
}
@font-face {
  font-family: "EncodeSansBold";
  src: url("./assets/fonts/EncodeSans/EncodeSans-Bold.ttf");
}

@font-face {
  font-family: "EncodeSansBolder";
  src: url("./assets/fonts/EncodeSans/EncodeSans-Black.ttf");
}

@font-face {
  font-family: "EncodeSansSemiBold";
  src: url("./assets/fonts/EncodeSans/EncodeSans-SemiBold.ttf");
}
* {
  font-family: "EncodeSansRegular", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: #243882;
}

b {
  font-weight: normal;
  font-family: "EncodeSansBold", sans-serif;
  color: #000000;
}
span {
  color: #232323;
}

.sb {
  font-family: "EncodeSansSemiBold", sans-serif;
  color: #333;
}

/* TEMPORANEO */

.pe-pointer {
  cursor: pointer;
}

/* INIZIO CSS MODALI CUSTOM */

/* INIZIO CSS MODALI CONFERMA EVENTI */

.confirmEventModal {
  .contentWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 22px 63px;
  }

  .modal-content {
    width: 402px;
    min-height: 177px;
    background: #f4f4f4;
    border: 2px solid #dbdbdb;
    box-shadow: 0px 47px 19px rgba(0, 0, 0, 0.01),
      0px 27px 16px rgba(0, 0, 0, 0.05), 0px 12px 12px rgba(0, 0, 0, 0.09),
      0px 3px 7px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-image: url("./assets/icons/bgImgConfirmModal.svg");
    background-position: bottom left;
    background-repeat: no-repeat;
  }

  .contentTitle {
    margin-bottom: 12px;
  }
  .contentMainText {
    margin-bottom: 24px;
  }
  .cancelButton {
    padding: 8px 16px;
    width: 75px;
    height: 35px;
    border: 1px solid #243882;
    border-radius: 4px;
    font-size: 15px;
    line-height: 19px;
    color: #243882;
    margin-right: 8px;
  }
  .confirmButton {
    padding: 8px 16px;
    width: 85px;
    height: 35px;
    background: #0a7e47;
    border-radius: 4px;
    font-size: 15px;
    line-height: 19px;
    color: #ffffff;
    border: none;
  }

  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
  }
}
/* FINE CSS MODALI CONFERMA EVENTI */

/* FINE CSS MODALI CUSTOM */

html,
body {
  height: 100%;
  width: 100%;
}

/* CKeditor custom styles */
.ck.ck-toolbar {
  border: none !important;
  background-color: #fafafa !important;
  position: sticky !important;
  top: 0;
}
.ck-rounded-corners {
  border-radius: 10px !important;
}
.ck-content {
  min-height: 93px !important;
}
.ck-focused {
  border: none !important;
}

/* .editorCustomStyle {
  border: 1px solid #ccced1;
  border-radius: 3px;
  height: 150px;
}
 */

.leaflet-tile {
  border: solid rgb(157 157 157) 1px;
}
.leaflet-bottom {
  //Hide leaflet logo
  display: none;
}
.grab-cursor {
  cursor: grab !important;
}
.pointer-cursor {
  cursor: pointer !important;
}

.areYouSureModal {
  position: absolute;
  right: 27px;
  top: 542px;
}

.areYouSureModal .modal-content {
  background-color: transparent;
  border: none;
}

.leaflet-layer:first-of-type {
  filter: grayscale(1) !important;
}

//LOTTIE CSS FOR MARKER ON MAP by MG

.map-token-icon {
  display: block;
  position: relative;
  z-index: 200;

  // > img {
  //   position: relative;
  //   z-index: 200;
  // }

  // > svg {
  //   /*position: relative;
  //   z-index: 199;
  //   display: block;
  //    width: 150px !important;
  //   height: 150px !important;
  //   transform: translate3d(-50%,-50%,0) !important; */

  //   position: absolute;
  //   z-index: 199;
  //   display: block;
  //   top: 36px;
  //   right: 1px;
  // }

  .map-token-icon-clock {
    position: absolute;
    top: -5px;
    right: -5px;
  
  }
  .map-token-icon-ellipse {
    position: absolute;
    cursor: grab;
    z-index: 199;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    animation: emitCircles 1s infinite;
    animation-timing-function: ease-in-out;
    display: none;
    pointer-events: none;
  }
  .map-token-icon-logo {

  }
  
  @keyframes emitCircles {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(6);
      opacity: 0.5;
    }
  }
}
.selected-map-token-icon {
  .map-token-icon-ellipse{
    display:block
  }
}


.group-marker {
  background-color: #1e3a8a; /* Colore blu */
  border-radius: 12px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.group-number {
  color:white;
  position: relative;
  top: -2px;
}





.leaflet-div-icon {
  background: transparent;
  border: none;
}
/* tooltipAccident */
/* TOOLTIP CUSTOM */

.tooltip.customTooltip {
  padding-bottom: 9.4px;
  opacity: 1;
  margin-left: 39px !important;
  &.bs-tooltip-top,
  &.bs-tooltip-auto[data-popper-placement^="top"] {
    .tooltip-arrow {
      &::before {
        top: -3px;
        left: -40px;
        border-top: 6px solid;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
      }
      &::after {
        content: "";
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid;
        top: -4px;
        left: -40px;
        border-top: 6px solid #fff;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
      }
    }
  }

  .tooltip-inner {
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    width: 144px;
    height: 54px;
    padding: 6px 19px;
  }

  .expTime {
    &Title {
      display: flex;
      b {
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */

        color: #000000;
      }
    }
    &Content {
      display: flex;

      span {
        font-size: 10px;
        line-height: 18px;
        color: #565656;
      }
    }
  }

  &.tooltipAccident {
    &.bs-tooltip-top,
    &.bs-tooltip-auto[data-popper-placement^="top"] {
      .tooltip-arrow::before {
        border-top-color: #cd00ee;
      }
    }

    .tooltip-inner {
      background: #ffffff;
      border: 1px solid #cd00ee;
    }
  }
  &.tooltipTraffic {
    &.bs-tooltip-top,
    &.bs-tooltip-auto[data-popper-placement^="top"] {
      .tooltip-arrow::before {
        border-top-color: #ee0000;
      }
    }

    .tooltip-inner {
      background: #ffffff;
      border: 1px solid #ee0000;
    }
  }
  &.tooltipConstruction {
    &.bs-tooltip-top,
    &.bs-tooltip-auto[data-popper-placement^="top"] {
      .tooltip-arrow::before {
        border-top-color: #0dc709;
      }
    }

    .tooltip-inner {
      background: #ffffff;
      border: 1px solid #0dc709;
    }
  }

  &.tooltipWeather {
    &.bs-tooltip-top,
    &.bs-tooltip-auto[data-popper-placement^="top"] {
      .tooltip-arrow::before {
        border-top-color: #00b5aa;
      }
    }

    .tooltip-inner {
      background: #ffffff;
      border: 1px solid #00b5aa;
    }
  }
}

button.delete-marker-button {
  border: none; 
  color: white; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
  cursor: pointer; 
  z-index: 1000; 
  font-size: 18px; 
  padding: 0; 
  margin: -23px 0;
  background-color: #FF4D4D !important;
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 999 !important;
  // &:hover {
  //   background-color: #FF6666; 
  // }
}

button.delete-marker-icon {
  color: white;
}

.toastContainer {
  position: absolute;
  padding: 17px 25px 17px 28px !important;
  width: 249px !important;
  height: 52px !important;
  margin-top: 24px !important;
  &.error {
    background: #ffd5c8;
  }
  &.success {
    background: #c8ffc8;
  }
  box-shadow: 7px 14px 34px rgba(0, 0, 0, 0.08), 0px 0px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.toastMessage {
  font-family: "EncodeSansRegular", sans-serif;
  font-size: 14px;
  line-height: 18px;
  &.error {
    color: #940000;
    &:before {
      content: "\00a0";
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 5L5 15' stroke='%23940000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 5L15 15' stroke='%23940000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat no-repeat;
      background-position: center center;
      background-size: cover;
      width: 20px;
      height: 20px;
      display: inline-block;
    }
  }
  &.success {
    color: #059400;
    &:before {
      content: "\00a0";
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 4.5L6.75 12.75L3 9' stroke='%23059400' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat no-repeat;
      background-position: center center;
      background-size: cover;
      width: 20px;
      height: 20px;
      display: inline-block;
    }
  }
}

.modal-overlay {
  position: fixed;
  bottom: -1000px;
  right: -500px;
  width: 2700px;
  height: 2700px;
  border-radius: 2690px;
  background: radial-gradient(48.55% 48.55% at 50% 50%, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
  z-index: 8998;
}
